export enum SimpleJobState {
  queued = 'queued',
  processing = 'processing',
  completed = 'completed',
  failed = 'failed',
  unknown = 'unknown',
}

export type HyperTableChunk = {
  hypertable_name: string;
  chunk_name: string;
  range_start: string;
  range_end: string;
  total_bytes: number;
};

type DroppedChunk = {
  data_from_ts: string;
  offline_from_ts: string;
  drop_chunks: string;
};

export type BatteryChargeMigrationJobData = {
  queuedAt: Date;
  startedAt?: Date;
  finishedAt?: Date;
  result?:
    | 'no_chunk_to_migrate'
    | 'paused'
    | 'chunk_migration_complete'
    | 'deleted_migrated_chunks';
  chunk: HyperTableChunk;
  startingInstallationCount?: number;
  processedInstallationCount?: number;
  droppedChunks?: DroppedChunk[];
};

export type HyperTable = {
  hypertable_schema: string;
  hypertable_name: string;
  owner: string;
  num_dimensions: number;
  num_chunks: number;
  compression_enabled: boolean;
  is_distributed: boolean;
  replication_factor: number | null;
  data_nodes: number | null;
  tablespaces: string | null;
};

export type HyperTableWithSize = HyperTable & {
  hypertable_size: number | null;
};

export enum BatteryChargingProfile {
  None = 'none',
  Test = 'test',
  HemsSetting = 'hems_setting',
}

export enum DeviceType {
  battery = 'battery',
  meter = 'meter',
  wallbox = 'wallbox',
  heatPump = 'heatPump',
  heatingRod = 'heatingRod',
}

export enum PriceSignalAlgorithm {
  none = 'none',
  heatPumpV1 = 'heatPumpV1',
}
