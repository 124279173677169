import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { useGetElectricityPricesQuery } from '../../../redux/api/iotCloud';
import ServicePortalLayout from '../ServicePortalLayout';
import { useTranslation } from 'react-i18next';
import PriceBarChart from './PriceBarChart';
import {
  addDays,
  addHours,
  differenceInHours,
  endOfDay,
  isAfter,
  isBefore,
  startOfDay,
  startOfHour,
  subDays,
  subHours,
} from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { debounce, sortBy } from 'lodash';
import LoadingCard from '../InstallationDetailsPage/LoadingCard';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { GlobalParamEnum, useGlobalParam } from '../../../lib/hooks';
import { SubmittedParamExposeType } from '../../../redux/servicePortal';

export type DateRange = {
  start: Date | number;
  end: Date | number;
};

export type NumberDateRange = {
  start: number;
  end: number;
};

export type Range = DateRange | NumberDateRange;

export const rangeToFromTo = (range: Range) => {
  const hours = differenceInHours(new Date(range.end), new Date(range.start));
  const from = subHours(new Date(range.start), hours);
  const to = addHours(new Date(range.end), hours);
  return { from, to };
};

export const createHandleRangeChange =
  (
    setRange: (range: NumberDateRange) => any,
    setFromTo: (newVal: { from: Date; to: Date }) => any,
  ) =>
  (
    newStart: Date | number | string,
    newEnd: Date | number | string,
    fullDays = false,
  ) => {
    const startDate = new Date(newStart);
    const endDate = new Date(newEnd);
    const start = (
      fullDays ? startOfHour(startOfDay(startDate)) : startDate
    ).valueOf();
    const end = (fullDays ? startOfHour(endOfDay(endDate)) : endDate).valueOf();
    setRange({ start, end });
    setFromTo(rangeToFromTo({ start, end }));
  };

export const createOnFromDateFilterChange =
  (
    range: Range,
    handleRangeChange: (
      newStart: Date | number,
      newEnd: Date | number,
      fullDays: boolean,
    ) => void,
  ) =>
  (date: Date | null) => {
    if (isAfter(date ?? range.start, range.end)) {
      handleRangeChange(
        date ?? range.start,
        addDays(date ?? range.start, 1),
        true,
      );
    } else {
      handleRangeChange(date ?? range.start, range.end, true);
    }
  };
export const createOnToDateFilterChange =
  (
    range: Range,
    handleRangeChange: (
      newStart: Date | number,
      newEnd: Date | number,
      fullDays: boolean,
    ) => void,
  ) =>
  (date: Date | null) => {
    if (isBefore(date ?? range.end, range.start)) {
      handleRangeChange(subDays(date ?? range.end, 1), date ?? range.end, true);
    } else {
      handleRangeChange(range.start, date ?? range.end, true);
    }
  };

const ElectricityPricePage = () => {
  const { t } = useTranslation('translation');

  const initialRange = useMemo(
    () => ({
      start: startOfHour(subDays(new Date(), 1)).valueOf(),
      end: startOfHour(addDays(new Date(), 1)).valueOf(),
    }),
    [],
  );

  const [, range, , setRange] = useGlobalParam<
    NumberDateRange,
    NumberDateRange
  >(GlobalParamEnum.EP_Range, {
    expose: SubmittedParamExposeType.URL_JSON,
    replaceUrlHistory: true,
    initial: initialRange,
  });
  const rangeDate = useMemo(
    () => ({ start: new Date(range.start), end: new Date(range.end) }),
    [range],
  );
  const [{ from, to }, setFromTo] = useState(rangeToFromTo(range));

  const queryArgs = useMemo(() => {
    return {
      from: from.toISOString(),
      to: to.toISOString(),
    };
  }, [from, to]);

  const {
    data: prices,
    isFetching,
    isLoading,
  } = useGetElectricityPricesQuery(queryArgs);

  const finalPrices = useMemo(
    () =>
      sortBy(
        prices?.map((price, i) => {
          return {
            ...price,
            ts: new Date(price.timestamp),
          };
        }),
        'ts',
      ),
    [prices],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRangeChange = useCallback(
    createHandleRangeChange(setRange, setFromTo),
    [setRange, setFromTo],
  );
  const debouncedHandleRangeChange = useMemo(() => {
    return debounce(handleRangeChange, 1000);
  }, [handleRangeChange]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFromDateFilterChange = useCallback(
    createOnFromDateFilterChange(range, handleRangeChange),
    [range, handleRangeChange],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onToDateFilterChange = useCallback(
    createOnToDateFilterChange(range, handleRangeChange),
    [range, handleRangeChange],
  );

  return (
    <ServicePortalLayout>
      <Box
        sx={{
          my: 1,
          height: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {isFetching && <LinearProgress />}
      </Box>
      <Typography variant="h3" sx={{ pb: 5 }}>
        {t('servicePortal.electricityPricePage.title')}
      </Typography>
      <Stack direction="row" gap={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableFuture
            label={t('common.fromDateLabel')}
            onChange={onFromDateFilterChange}
            value={rangeDate.start}
          />
          <DatePicker
            label={t('common.toDateLabel')}
            onChange={onToDateFilterChange}
            value={rangeDate.end}
          />
        </LocalizationProvider>
      </Stack>
      <LoadingCard isLoading={isLoading} isLoadingWithContent={isFetching}>
        <PriceBarChart
          mode="value"
          prices={finalPrices ?? []}
          onChangeRange={debouncedHandleRangeChange}
          range={rangeDate}
        />
      </LoadingCard>
    </ServicePortalLayout>
  );
};

export default ElectricityPricePage;
