import { FormControl, InputLabel, Select, SelectProps } from '@mui/material';

type LabeledSelectType = <T>(props: SelectProps<T>) => JSX.Element;

export const LabeledSelect: LabeledSelectType = ({
  label,
  children,
  labelId,
  ...rest
}) => {
  const finalLabelId =
    labelId ?? typeof label === 'string' ? (label as string) : undefined;
  return (
    <FormControl size="medium">
      <InputLabel shrink htmlFor={finalLabelId}>
        {label}
      </InputLabel>
      <Select
        sx={{ minWidth: 175 }}
        label={label}
        labelId={finalLabelId}
        {...rest}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default LabeledSelect;
