import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type ServicePortalState = {
  sharedInput: Record<string, string | number | boolean | object | null>;
  submittedInput: Record<
    string,
    {
      value: string | number | boolean | object | null;
      expose: SubmittedParamExposeType;
    }
  >;
};

const initialState: ServicePortalState = {
  sharedInput: {},
  submittedInput: {},
};

export enum SubmittedParamExposeType {
  NONE = 'none',
  URL = 'url',
  URL_JSON = 'urlJson',
}

const servicePortalSlice = createSlice({
  name: 'servicePortal',
  initialState,
  reducers: {
    setSharedInputValue(
      state,
      action: PayloadAction<{
        key: string;
        value: string | number | boolean | object;
      }>,
    ) {
      state.sharedInput[action.payload.key] = action.payload.value;
    },
    setSubmittedInputValue(
      state,
      action: PayloadAction<{
        key: string;
        value: string | number | boolean | object;
        expose?: SubmittedParamExposeType;
      }>,
    ) {
      state.submittedInput[action.payload.key] = {
        value: action.payload.value,
        expose: action.payload.expose ?? SubmittedParamExposeType.NONE,
      };
    },
  },
});

export const { setSharedInputValue, setSubmittedInputValue } =
  servicePortalSlice.actions;

export default servicePortalSlice;
