import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Rectangle,
  ResponsiveContainer,
} from 'recharts';
import { Card, Typography } from '@mui/material';
import { isEmpty, isNil, max, min } from 'lodash';
import { formatTimestamp } from '../../../lib/formatting';
import { useCallback, useMemo, useState } from 'react';

export type PriceChartItem = {
  timestamp: string;
  value: number;
  activeValue?: number | null;
  inactiveValue?: number | null;
};

type CustomTooltipProps = {
  active: boolean;
  payload: PriceChartItem[];
  label: string;
  [key: string]: any;
};

const CustomTooltip = (props: CustomTooltipProps | {}) => {
  if (!props || isEmpty(props)) {
    return null;
  }
  const { active, payload, label } = props as CustomTooltipProps;
  if (!active || !label || isNil(payload[0]?.value)) {
    return null;
  }
  return (
    <Card>
      <Typography>
        {`${formatTimestamp(label, {
          date: true,
          hours: true,
          minutes: true,
          seconds: false,
        })} : ${payload[0]?.value}`}
      </Typography>
    </Card>
  );
};

export const ConditionalBarShape = ({ payload, ...rest }: any) => {
  const color = payload.active ? '#05820b' : '#424242';
  return <Rectangle fill={color} {...rest} />;
};

export const ActiveConditionalBarShape = ({ payload, ...rest }: any) => {
  const color = payload.active ? '#00fb0c' : '#181818';
  return <Rectangle fill={color} {...rest} />;
};

export const ConditionalBarBackground = ({
  active,
  payload,
  x,
  y,
  width,
  height,
  ...rest
}: any) => {
  if (payload.active) {
    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          stroke="none"
          fill="#b4dbb6"
        />
      </g>
    );
  }
  return null;
};

const PriceBarChart = ({
  prices,
  range,
  onChangeRange,
  mode,
}: {
  prices: PriceChartItem[];
  range: { start: Date; end: Date };
  onChangeRange?: (from: string, to: string) => void;
  mode: 'value' | 'active_inActive';
}) => {
  const [{ width, height }, setDimensions] = useState({
    width: 600,
    height: 300,
  });
  const handleOnResize = useCallback(
    (width: number, height: number) => {
      setDimensions({
        width,
        height,
      });
    },
    [setDimensions],
  );
  const yTickCount = useMemo(() => (width - 110) / 50, [width]);
  const xTickFormatter = useCallback(
    (val: string) =>
      formatTimestamp(val, {
        date: true,
        year: false,
        hours: true,
        minutes: true,
        seconds: false,
      }) || '',
    [],
  );
  const xBrushTickFormatter = useCallback(
    (val: string) =>
      formatTimestamp(val, {
        date: true,
        hours: true,
        minutes: true,
        seconds: false,
      }) || '',
    [],
  );

  // do not memoise start and end index, they should change immediately
  const brushStartIndex = max([
    0,
    prices.findIndex(
      ({ timestamp }) =>
        new Date(timestamp).valueOf() === range.start.valueOf(),
    ),
  ]);
  const brushEndIndex = min([
    prices.length - 1,
    prices.findIndex(
      ({ timestamp }) => new Date(timestamp).valueOf() === range.end.valueOf(),
    ),
  ]);

  const onChangeBrush = useCallback(
    (newVal: { startIndex?: number; endIndex?: number }) => {
      if (onChangeRange) {
        const start = prices[newVal.startIndex ?? 0]?.timestamp;
        const end = prices[newVal.endIndex ?? prices.length]?.timestamp;
        onChangeRange(start, end);
      }
    },
    [prices, onChangeRange],
  );

  // TODO: implement zooming: https://recharts.org/en-US/examples/HighlightAndZoomLineChart

  return (
    <div style={{ width: '100%', height: '50vh' }}>
      <ResponsiveContainer onResize={handleOnResize}>
        <BarChart
          width={width}
          height={height}
          data={prices}
          margin={{
            top: 0,
            right: 30,
            left: 0,
            bottom: 60,
          }}
          barCategoryGap={0}
        >
          <Brush
            dataKey="timestamp"
            y={height - 30}
            height={30}
            stroke="#8884d8"
            startIndex={brushStartIndex}
            endIndex={brushEndIndex}
            tickFormatter={xBrushTickFormatter}
            onChange={onChangeBrush}
            gap={min([1, Math.round(prices.length / 24)])}
            y1={20}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="timestamp"
            angle={45}
            textAnchor="start"
            tickFormatter={xTickFormatter}
            interval="preserveStartEnd"
          />

          <YAxis tickCount={yTickCount} />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
          <ReferenceLine y={0} stroke="#000" />
          {mode === 'value' && (
            <Bar isAnimationActive={false} dataKey="value" fill="#3b61af" />
          )}
          {mode === 'active_inActive' && (
            <Bar
              activeBar={<ActiveConditionalBarShape />}
              isAnimationActive={false}
              dataKey="value"
              shape={<ConditionalBarShape />}
              background={<ConditionalBarBackground />}
            ></Bar>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PriceBarChart;
