import {
  Box,
  MenuItem,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  useGetPriceSignalAlgorithmsQuery,
  useGetPriceSignalElectricityPricesQuery,
} from '../../../redux/api/iotCloud';
import ServicePortalLayout from '../ServicePortalLayout';
import { useTranslation } from 'react-i18next';
import PriceBarChart from './PriceBarChart';
import { addDays, endOfDay, startOfHour, subDays } from 'date-fns';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { debounce, sortBy } from 'lodash';
import LoadingCard from '../InstallationDetailsPage/LoadingCard';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  createHandleRangeChange,
  createOnFromDateFilterChange,
  createOnToDateFilterChange,
  NumberDateRange,
  rangeToFromTo,
} from './ElectricityPricePage';
import { DeviceType } from '../../../redux/api/iotCloud/typeCopy';
import { GlobalParamEnum, useGlobalParam } from '../../../lib/hooks';
import { SubmittedParamExposeType } from '../../../redux/servicePortal';
import CopyToClipboardButton from '../../common/CopyToClipboardButton';
import LabeledSelect from '../../common/Inputs/LabeledSelect';

const AlgorithmConfigForm = ({ algorithm }: { algorithm: string }) => {
  const { data, isLoading } = useGetPriceSignalAlgorithmsQuery();

  const algorithmDefaultConfig = useMemo(() => {
    return data?.find(pA => pA.algorithm === algorithm)?.defaultConfig;
  }, [data, algorithm]);
  const algorithmConfigOptions: any = useMemo(() => {
    return data?.find(pA => pA.algorithm === algorithm)?.configOptions;
  }, [data, algorithm]);

  // algorithm option selection
  const [
    algorithmConfig,
    submittedAlgorithmConfig,
    setAlgorithmConfig,
    submitAlgorithmConfig,
  ] = useGlobalParam<Record<string, any>>(
    GlobalParamEnum.PSAP_AlgorithmConfig,
    {
      expose: SubmittedParamExposeType.URL_JSON,
      replaceUrlHistory: true,
      initial: {},
    },
  );
  const debouncedSubmitAlgorithmConfig = useMemo(
    () => debounce(submitAlgorithmConfig, 1000),
    [submitAlgorithmConfig],
  );
  const handleConfigOptionChange = useCallback(
    (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const newConfig = {
        ...algorithmDefaultConfig,
        ...algorithmConfig,
        [key]: value,
      };
      setAlgorithmConfig(newConfig);
      debouncedSubmitAlgorithmConfig(newConfig);
    },
    [
      algorithmDefaultConfig,
      algorithmConfig,
      setAlgorithmConfig,
      debouncedSubmitAlgorithmConfig,
    ],
  );
  const handleConfigOptionChangeMap = useMemo(() => {
    if (!algorithmDefaultConfig) {
      return {};
    }
    return Object.keys(algorithmDefaultConfig).reduce(
      (acc, key) => ({
        ...acc,
        [key]: handleConfigOptionChange(key),
      }),
      {} as Record<string, (event: ChangeEvent<HTMLInputElement>) => void>,
    );
  }, [algorithmDefaultConfig, handleConfigOptionChange]);

  let inputs = (
    <Typography>No configuration options for this algorithm</Typography>
  );
  if (algorithmDefaultConfig) {
    inputs = (
      <Stack direction="row" gap={3} sx={{ flexWrap: 'wrap' }}>
        {Object.entries(algorithmDefaultConfig).map(
          ([key, defaultValue], i) => {
            const options = algorithmConfigOptions?.[key] ?? [];
            return (
              <TextField
                key={key}
                size="small"
                label={key}
                value={algorithmConfig[key] ?? defaultValue}
                onChange={handleConfigOptionChangeMap[key]}
                inputProps={{
                  inputMode:
                    typeof defaultValue === 'number' ? 'numeric' : 'text',
                }}
                sx={
                  `${algorithmConfig[key] ?? defaultValue}` !==
                  `${defaultValue}`
                    ? { backgroundColor: 'primary.light' }
                    : undefined
                }
                helperText={`Default: ${defaultValue}`}
                select={options.length > 0}
              >
                {options.map((option: string) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            );
          },
        )}
      </Stack>
    );
  }

  return (
    <LoadingCard isLoading={isLoading}>
      <Stack>
        {inputs}
        <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
          <Typography variant="h6">Current Configuration</Typography>
          <CopyToClipboardButton
            textToCopy={JSON.stringify(submittedAlgorithmConfig)}
          />
          <Typography sx={{ color: 'secondary.main' }}>
            {JSON.stringify(submittedAlgorithmConfig)}
          </Typography>
          <CopyToClipboardButton
            textToCopy={JSON.stringify(submittedAlgorithmConfig)}
          />
        </Box>
      </Stack>
    </LoadingCard>
  );
};

const ElectricityPriceAlgorithmPlaygroundPage = () => {
  const { t } = useTranslation('translation');
  const { t: tp } = useTranslation('translation', {
    keyPrefix: 'servicePortal.electricityPriceAlgorithmPlaygroundPage',
  });

  const [, deviceType, , setDeviceType] = useGlobalParam(
    GlobalParamEnum.DeviceType,
    {
      expose: SubmittedParamExposeType.URL,
      replaceUrlHistory: false,
      initial: DeviceType.heatPump,
    },
  );
  const handleDeviceTypeChange = useCallback(
    (event: SelectChangeEvent, child: React.ReactNode) => {
      setDeviceType(event.target.value as DeviceType);
    },
    [setDeviceType],
  );
  // algorithm selection
  const {
    data: priceSignalAlgorithms,
    isLoading: isLoadingPriceSignalAlgorithms,
  } = useGetPriceSignalAlgorithmsQuery();
  const [, algorithm, , setAlgorithm] = useGlobalParam(
    GlobalParamEnum.PSAP_Algorithm,
    {
      expose: SubmittedParamExposeType.URL,
      replaceUrlHistory: false,
      initial: 'default',
    },
  );
  const [, algorithmConfig] = useGlobalParam(
    GlobalParamEnum.PSAP_AlgorithmConfig,
    {
      expose: SubmittedParamExposeType.URL_JSON,
      replaceUrlHistory: true,
      initial: {},
    },
  );
  const handleAlgorithmChange = useCallback(
    (event: SelectChangeEvent) => setAlgorithm(event.target.value as string),
    [setAlgorithm],
  );

  const initialRange = useMemo(
    () => ({
      start: startOfHour(subDays(new Date(), 1)).valueOf(),
      end: startOfHour(addDays(new Date(), 1)).valueOf(),
    }),
    [],
  );

  const [, range, , setRange] = useGlobalParam<
    NumberDateRange,
    NumberDateRange
  >(GlobalParamEnum.EP_Range, {
    expose: SubmittedParamExposeType.URL_JSON,
    replaceUrlHistory: true,
    initial: initialRange,
  });
  const rangeDate = useMemo(
    () => ({ start: new Date(range.start), end: new Date(range.end) }),
    [range],
  );
  const [{ from, to }, setFromTo] = useState(rangeToFromTo(range));

  const queryArgs = useMemo(() => {
    return {
      from: from.toISOString(),
      to: endOfDay(to).toISOString(),
      algorithm: algorithm === 'default' ? undefined : `${algorithm}`,
      algorithmConfig,
      deviceType: deviceType as DeviceType,
    };
  }, [from, to, algorithm, deviceType, algorithmConfig]);

  const { data, isFetching, isLoading } =
    useGetPriceSignalElectricityPricesQuery(queryArgs);

  const finalPrices = useMemo(
    () =>
      sortBy(
        data?.periods?.map((period, i) => {
          return {
            timestamp: period.from,
            fromTs: new Date(period.from),
            value: period.price,
            active: period.active,
          };
        }),
        'fromTs',
      ),
    [data],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRangeChange = useCallback(
    createHandleRangeChange(setRange, setFromTo),
    [setRange, setFromTo],
  );
  const debouncedHandleRangeChange = useMemo(() => {
    return debounce(handleRangeChange, 1000);
  }, [handleRangeChange]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFromDateFilterChange = useCallback(
    createOnFromDateFilterChange(range, handleRangeChange),
    [range, handleRangeChange],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onToDateFilterChange = useCallback(
    createOnToDateFilterChange(range, handleRangeChange),
    [range, handleRangeChange],
  );

  return (
    <ServicePortalLayout>
      <Stack direction="column" gap={3}>
        <Typography variant="h3" sx={{ pb: 5 }}>
          {t('servicePortal.electricityPriceAlgorithmPlaygroundPage.title')}
        </Typography>
        <LoadingCard>
          <Stack direction="row" gap={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disableFuture
                label={t('common.fromDateLabel')}
                onChange={onFromDateFilterChange}
                value={rangeDate.start}
              />
              <DatePicker
                label={t('common.toDateLabel')}
                onChange={onToDateFilterChange}
                value={rangeDate.end}
              />
            </LocalizationProvider>
            <LabeledSelect
              sx={{ width: 175, height: 53 }}
              value={`${deviceType}`}
              label={tp('deviceType')}
              onChange={handleDeviceTypeChange}
            >
              {Object.values(DeviceType).map(dT => (
                <MenuItem key={dT} value={dT}>
                  {t(`common.deviceType.${dT}`)}
                </MenuItem>
              ))}
            </LabeledSelect>
            {!isLoadingPriceSignalAlgorithms && (
              <LabeledSelect
                sx={{ width: 175, height: 53 }}
                value={`${algorithm}`}
                label={tp('algorithm')}
                onChange={handleAlgorithmChange}
                labelId="select-algorithm"
              >
                <MenuItem value="default">Default</MenuItem>
                {priceSignalAlgorithms?.map(algo => (
                  <MenuItem key={algo.algorithm} value={algo.algorithm}>
                    {algo.algorithm}
                  </MenuItem>
                ))}
              </LabeledSelect>
            )}
            {isLoadingPriceSignalAlgorithms && (
              <Skeleton width={125} height={54} variant="rectangular" />
            )}
          </Stack>
        </LoadingCard>
        <AlgorithmConfigForm algorithm={`${algorithm}`} />
        <LoadingCard isLoading={isLoading} isLoadingWithContent={isFetching}>
          <PriceBarChart
            mode="active_inActive"
            prices={finalPrices ?? []}
            onChangeRange={debouncedHandleRangeChange}
            range={rangeDate}
          />
        </LoadingCard>
      </Stack>
    </ServicePortalLayout>
  );
};

export default ElectricityPriceAlgorithmPlaygroundPage;
