import { format } from 'date-fns';

export const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (!bytes) {
    return '';
  }

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const parsedFileSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  const formattedNumber =
    new Intl.NumberFormat('de-DE').format(parsedFileSize) + ' ' + sizes[i];

  return formattedNumber;
};

export const formatDateISOstring = (
  dateISOString: string | null | undefined,
) => {
  if (!dateISOString) {
    return null;
  }

  return new Date(dateISOString).toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const formatShortDateISOstring = (
  dateISOString: string | null | undefined,
) => {
  if (!dateISOString) {
    return null;
  }

  return new Date(dateISOString).toLocaleDateString('de-DE', {
    month: '2-digit',
    year: 'numeric',
  });
};

export const formatTimestamp = (
  timestamp: string | number | Date | null | undefined,
  {
    date = true,
    year = true,
    hours = true,
    minutes = true,
    seconds = true,
  }: {
    date?: boolean;
    year?: boolean;
    hours?: boolean;
    minutes?: boolean;
    seconds?: boolean;
  } = {
    date: true,
    year: true,
    hours: true,
    minutes: true,
    seconds: true,
  },
) => {
  if (!timestamp) {
    return null;
  }

  return format(
    new Date(timestamp),
    `${date ? `dd.MM${year ? '.yyyy' : ''}` : ''}${hours ? ' HH' : ''}${
      minutes ? ':mm' : ''
    }${seconds ? ':ss' : ''}`,
  );
};
